html, body {
    margin: 0;
    padding: 0px;
    width: 100%;
    height: 100%;
    font-family: 'Open Sans';
}

a {
    color: #0077c7;
    text-decoration: none;
}

a:hover {
    color: #1fa5ff;
    text-decoration: underline;
}

h1
{
    font-size: 37px;
}

h2
{
    font-size: 24px;
    font-family: 'Open Sans';
    color: #000000;
    margin: 10px;
}